import { ApiClient } from '../apiClient/apiClient';
import { RecommendationType } from '../types/recommendation';

const apiClient = ApiClient.getInstance();

export const recommendationService = {
  async getRecommendationsFromUrl(
    url: string,
  ): Promise<{ recommendations: RecommendationType[] }> {
    try {
      return (await apiClient.post(`/recommendation/get-recommendations`, {
        url,
      })) as unknown as Promise<{ recommendations: RecommendationType[] }>;
    } catch (error) {
      console.error(`Error fetching recommendations for URL ${url}:`, error);
      throw error;
    }
  },
};
