import { useState } from 'react';

import { Heading } from '../components/catalyst-ui/heading';
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from '../components/catalyst-ui/listbox';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '../components/catalyst-ui/table';
import { Strong, Text } from '../components/catalyst-ui/text';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import PaginationComponent from '../components/PaginationComponent';
import { analyticService } from '../services/AnalyticService';
import { ArticleResponse } from '../types/articleTypes';

const Analytics = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [country, setCountry] = useState<'CZ' | 'SK' | 'HU' | null>('CZ');
  const [range, setRange] = useState<'1' | '7' | '90' | null>('7');
  const ITEM_PER_PAGE = 10;
  const {
    data: analyticsData,
    isLoading,
    isError,
    error,
  } = analyticService.getAnalyticsData(country, range, currentPage);
  const totalResults = analyticsData?.totalResults;

  const getPageViewsByRange = (article: ArticleResponse) => {
    switch (range) {
      case '1':
        return article.pageviews1;
      case '7':
        return article.pageviews7;
      case '90':
        return article.pageviews90;
      default:
        return '';
    }
  };

  const handleCountryChange = (country: 'CZ' | 'SK' | 'HU' | null) => {
    setCountry(country);
    setCurrentPage(1);
  };

  const handleRangeChange = (range: '1' | '7' | '90' | null) => {
    setRange(range);
    setCurrentPage(1);
  };

  if (isError) {
    return (
      <ErrorComponent error={error} message="Error fetching analytics data" />
    );
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row w-full flex-wrap sm:items-end sm:justify-between gap-4 pb-6 ">
        <Heading>Analytics</Heading>
        <div className="flex gap-4">
          <Listbox
            name="country"
            onChange={handleCountryChange}
            placeholder="Select country&hellip;"
            value={country}
          >
            <ListboxOption value="CZ">
              <ListboxLabel>Czech Republic</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="SK">
              <ListboxLabel>Slovakia</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="HU">
              <ListboxLabel>Hungary</ListboxLabel>
            </ListboxOption>
          </Listbox>
          <Listbox
            name="range"
            onChange={handleRangeChange}
            placeholder="Select range&hellip;"
            value={range ?? null}
          >
            <ListboxOption value="1">
              <ListboxLabel>1 day</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="7">
              <ListboxLabel>7 days</ListboxLabel>
            </ListboxOption>
            <ListboxOption value="90">
              <ListboxLabel>90 days</ListboxLabel>
            </ListboxOption>
          </Listbox>
        </div>
      </div>

      {country && range ? (
        <>
          {isLoading ? (
            <LoadingSpinner size="large" />
          ) : (
            <Table>
              <TableHead>
                <TableCell>No.</TableCell>
                <TableCell>Article title</TableCell>
                <TableCell>Views</TableCell>
              </TableHead>
              <TableBody>
                {analyticsData?.data?.map((item, index) => (
                  <TableRow key={item.postId} href={`/articles/${item.postId}`}>
                    <TableCell className="w-50">
                      {(currentPage - 1) * ITEM_PER_PAGE + index + 1}
                    </TableCell>
                    <TableCell className="w-full">{item.title}</TableCell>
                    <TableCell className="w-50">
                      {getPageViewsByRange(item)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}

          <PaginationComponent
            currentPage={currentPage}
            totalResults={totalResults ?? 0}
            itemPerPage={ITEM_PER_PAGE}
            onBackButtonClick={() => setCurrentPage(currentPage - 1)}
            onNextButtonClick={() => setCurrentPage(currentPage + 1)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      ) : (
        <div className="mt-24 flex justify-center items-center text-center">
          <Text className="!text-base/10">
            Please select a <Strong>country</Strong> from the list and specify a{' '}
            <Strong>range</Strong> of days for your analysis.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Analytics;
