import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Input, InputGroup } from './catalyst-ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './catalyst-ui/table';
import { LoadingSpinner } from './LoadingSpinner';

export interface TableComponentProps {
  headers: string[];
  rows: {
    data: (string | number)[];
    url?: string;
  }[];
  className?: string;
  title?: string;
  withSearch?: boolean;
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
}

const TableComponent = ({
  headers,
  rows,
  className,
  title,
  withSearch,
  onSearchChange,
  isLoading,
}: TableComponentProps) => {
  const navigate = useNavigate();

  const handleClick = (url: string | undefined) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className={className}>
      {title || withSearch ? (
        <div className="flex items-end justify-between gap-4">
          {title && (
            <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8">
              {title}
            </h1>
          )}

          {withSearch && (
            <InputGroup>
              <MagnifyingGlassIcon />
              <Input
                name="search"
                placeholder="Search&hellip;"
                aria-label="Search"
                onChange={onSearchChange}
              />
            </InputGroup>
          )}
        </div>
      ) : null}

      {!isLoading ? (
        <Table className="pt-8">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableHeader key={index}>{header}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={`hover:bg-zinc-100 ${row.url ? 'cursor-pointer' : 'cursor-default'}`}
              >
                {row.data.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    onClick={() => handleClick(row.url)}
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <LoadingSpinner size="large" />
      )}
    </div>
  );
};

export default TableComponent;
