import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from '../components/catalyst-ui/description-list';
import { Heading } from '../components/catalyst-ui/heading';
import { TextLink } from '../components/catalyst-ui/text';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import Tooltip from '../components/Tooltip';
import clickService from '../services/ClickService';
import formatTimestamp from '../utils/fromatTimeStamp';

const ClickDetail: React.FC = () => {
  const { id } = useParams();

  const {
    data: clickData,
    isLoading,
    isError,
    error,
  } = clickService.getClickById(id ?? '');

  if (isLoading) {
    return <LoadingSpinner size="full" />;
  }

  if (isError) {
    return <ErrorComponent message={'Error fetching click'} error={error} />;
  }

  return (
    <div>
      <Link to="/clicks">
        <div className="flex items-center gap-2 mb-10">
          <ChevronLeftIcon className="size-4 text-zinc-500" />
          <span className="text-md text-zinc-500">Clicks</span>
        </div>
      </Link>
      {id && clickData && (
        <div>
          <Heading>Click #{id}</Heading>

          <div className="mt-12">
            <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
              Summary
            </h2>
            <DescriptionList className="border-t border-zinc-950/10 mt-4">
              {[
                {
                  term: 'Post Title',
                  value: clickData.postTitle,
                },
                { term: 'User ID', value: clickData.userId },
                { term: 'User Token', value: clickData.userToken },
                {
                  term: 'Timestamp',
                  value: formatTimestamp(clickData.timestamp),
                },
                { term: 'UTM Referer', value: clickData.utm_referer },
                { term: 'UTM Source', value: clickData.utm_source },
                { term: 'UTM Medium', value: clickData.utm_medium },
                { term: 'UTM Position', value: clickData.utm_position },
                { term: 'UTM Timestamp', value: clickData.utm_timestamp },
                { term: 'UTM Device', value: clickData.utm_device },
                { term: 'UTM System', value: clickData.utm_system },
                {
                  term: 'Needs Aggregation',
                  value: clickData.needsAggregation ? 'Yes' : 'No',
                },
              ].map(({ term, value }) => (
                <>
                  <DescriptionTerm key={term}>{term}</DescriptionTerm>
                  <DescriptionDetails
                    className="break-all"
                    key={`${term}-value`}
                  >
                    {term === 'Post Title' && value !== 'Unknown title' ? (
                      <TextLink
                        href={`/articles/${clickData.postId}`}
                        className="text-zinc-500 hover:underline"
                      >
                        {value ?? '-'}
                      </TextLink>
                    ) : term === 'Post Title' && value === 'Unknown title' ? (
                      <Tooltip
                        content="This post wasn't found in the database"
                        position="left"
                      >
                        <span className="text-zinc-500">{value}</span>
                      </Tooltip>
                    ) : (
                      (value ?? '-')
                    )}
                  </DescriptionDetails>
                </>
              ))}
            </DescriptionList>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClickDetail;
