import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { ApiClient } from '../apiClient/apiClient';
import { ArticleRequestResponse, ArticleResponse } from '../types/articleTypes';

const apiClient = ApiClient.getInstance();

export const articleService = {
  getArticles(
    page: number,
    pageSize: number,
    searchTerm: string,
    country: 'HU' | 'CZ' | 'SK' | 'ALL' | '',
  ): UseQueryResult<ArticleRequestResponse> {
    if (country === 'ALL') {
      country = '';
    }

    return useQuery<ArticleRequestResponse>({
      queryKey: ['articles', page, pageSize, searchTerm, country],
      queryFn: async () => {
        const response = await apiClient.get(`/article`, {
          page,
          pageSize,
          searchTerm,
          country,
        });
        return response as unknown as ArticleRequestResponse;
      },
    });
  },

  getArticleById(id: string): UseQueryResult<ArticleResponse> {
    return useQuery<ArticleResponse>({
      queryKey: ['article', id],
      queryFn: async () => {
        const response = await apiClient.get(`/article/id`, {
          id,
        });
        return response as unknown as ArticleResponse;
      },
    });
  },

  deleteArticleById(id: string): Promise<AxiosResponse> {
    return apiClient.delete(`/article`, { postId: id, hardDelete: true });
  },
};
