import { useState } from 'react';

import ErrorComponent from '../components/ErrorComponent';
import PaginationComponent from '../components/PaginationComponent';
import TableComponent from '../components/TableComponent';
import clickService from '../services/ClickService';
import formatTimestamp from '../utils/fromatTimeStamp';

const Clicks = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data: clicks,
    isLoading,
    isError,
    error,
  } = clickService.getClickList(10, currentPage);

  const totalResults = clicks?.totalResults ?? 0;

  const clickHeaders = [
    'ID',
    'Post ID',
    'Needs Aggregation',
    'Timestamp',
    'User ID',
    'User Token',
  ];

  const getClickRow = () => {
    return clicks?.data.map((click) => {
      return {
        data: [
          click._id,
          click.postTitle,
          click.needsAggregation ? 'Yes' : 'No',
          formatTimestamp(click.timestamp),
          click.userId,
          click.userToken,
        ],
        url: `/clicks/${click._id}`,
      };
    });
  };

  if (isError) {
    return <ErrorComponent message={'Error fetching clicks'} error={error} />;
  }

  return (
    <div>
      <TableComponent
        headers={clickHeaders}
        rows={getClickRow() ?? []}
        className="mt-10"
        title="Clicks"
        isLoading={isLoading}
      />
      <PaginationComponent
        currentPage={currentPage}
        itemPerPage={10}
        totalResults={totalResults}
        onBackButtonClick={() => setCurrentPage(currentPage - 1)}
        onNextButtonClick={() => setCurrentPage(currentPage + 1)}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default Clicks;
