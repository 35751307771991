import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ApiClient } from '../apiClient/apiClient';
import { UserResponse, UsersRequest } from '../types/user';

const apiClient = ApiClient.getInstance();

export const userService = {
  getUserById(id: string): UseQueryResult<UserResponse> {
    return useQuery<UserResponse>({
      queryKey: ['user', id],
      queryFn: async () => {
        const response = await apiClient.get(`/user/id`, { id });
        return response as unknown as UserResponse;
      },
    });
  },

  getUsers(
    page: number,
    pageSize: number = 20,
    searchTerm?: string,
  ): UseQueryResult<UsersRequest> {
    if (searchTerm?.length && searchTerm?.length < 3) {
      searchTerm = undefined;
    }

    return useQuery<UsersRequest>({
      queryKey: ['users', page, pageSize, searchTerm],
      queryFn: async () => {
        const response = await apiClient.get(`/user`, {
          page,
          pageSize,
          searchTerm,
        });
        return response as unknown as UsersRequest;
      },
    });
  },
};
