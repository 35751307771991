import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ApiClient } from '../apiClient/apiClient';
import { Click, ClickListDTO } from '../types/click';

const apiClient = ApiClient.getInstance();
const clickService = {
  getClickList(limit = 10, page = 1): UseQueryResult<ClickListDTO> {
    return useQuery<ClickListDTO>({
      queryKey: ['clicks', limit, page],
      queryFn: async () => {
        const response = await apiClient.get('clicker/paginated-clicks', {
          limit,
          page,
        });
        return response as unknown as ClickListDTO;
      },
    });
  },

  getClickById(id: string): UseQueryResult<Click> {
    return useQuery<Click>({
      queryKey: ['click', id],
      queryFn: async () => {
        const response = await apiClient.get(`clicker/id`, { id });
        return response as unknown as Click;
      },
    });
  },
};

export default clickService;
