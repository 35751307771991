import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Avatar } from '../components/catalyst-ui/avatar';
import { Button } from '../components/catalyst-ui/button';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from '../components/catalyst-ui/description-list';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from '../components/catalyst-ui/dialog';
import { TextLink } from '../components/catalyst-ui/text';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import Tile from '../components/Tile';
import useStatistics from '../hooks/useStatistics';
import { articleService } from '../services/ArticleService';
import { statisticsService } from '../services/StatisticsService';
import { TimePeriod } from '../types/statisticsTypes';
import { formatDate } from '../utils/formatDate';
import { isLink } from '../utils/isLink';

const ArticleDetail: React.FC = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const timePeriod: TimePeriod = '7Days';
  const { calculatePercentageChange } = useStatistics();
  const {
    data: articleData,
    isLoading: isArticleLoading,
    isError: isArticleError,
    error,
  } = articleService.getArticleById(id ?? '');
  const {
    data: pageviews,
    isLoading: isPageviewsLoading,
    isError: isPageviewsError,
  } = statisticsService.getPageviewsByArticleId(id ?? '');
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const renderValue = (value: string | React.ReactNode) => {
    if (!value) {
      return '-';
    }

    if (typeof value === 'string') {
      return isLink(value) ? (
        <TextLink href={value} target="_blank">
          {value}
        </TextLink>
      ) : (
        value
      );
    }
    return value;
  };

  const getClicksPercentageChange = () => {
    const clicks14to7DaysAgo = articleData?.clicks14to7DaysAgo ?? 0;
    const clicksLast7Days = articleData?.clicksLast7Days ?? 0;
    return calculatePercentageChange(clicks14to7DaysAgo, clicksLast7Days);
  };

  const getUsersPercentageChange = () => {
    const users14to7DaysAgo = articleData?.users14to7DaysAgo ?? 0;
    const usersLast7Days = articleData?.usersLast7Days ?? 0;
    return calculatePercentageChange(users14to7DaysAgo, usersLast7Days);
  };

  const getPageviewsPercentageChange = () => {
    const pageviews14to7DaysAgo = pageviews?.fourteenDays ?? 0;
    const pageviewsLast7Days = pageviews?.sevenDays ?? 0;
    return calculatePercentageChange(pageviews14to7DaysAgo, pageviewsLast7Days);
  };

  const deleteArticle = async () => {
    if (!id) return;

    setAlertOpen(true);
  };

  const handleDeleteConfirmation = async (isConfirmed: boolean) => {
    setAlertOpen(false);

    if (!isConfirmed) return;

    try {
      if (!id) return;
      await articleService.deleteArticleById(id);
      queryClient.invalidateQueries({ queryKey: ['articles'] });
      navigate('/articles');
      toast.success('Article deleted successfully');
    } catch (error) {
      console.error('Failed to delete the article:', error);
      toast.error('Failed to delete the article');
    }
  };

  if (isArticleLoading || isPageviewsLoading) {
    return <LoadingSpinner size="full" />;
  }

  if (isArticleError || isPageviewsError) {
    return (
      <ErrorComponent
        message={'Error fetching article'}
        error={error ?? undefined}
      />
    );
  }

  return (
    <div>
      <Link to="/articles" className="block w-fit">
        <div className="flex items-center gap-2 mb-10">
          <ChevronLeftIcon className="size-4 text-zinc-500" />
          <span className="text-md text-zinc-500">Articles</span>
        </div>
      </Link>

      <div className="flex flex-col md:flex-row items-start justify-between pb-4">
        <div className="flex gap-6 sm:flex-row flex-col">
          <Avatar
            src={
              articleData?.featuredmedia &&
              articleData?.featuredmedia !== 'no-image'
                ? articleData?.featuredmedia
                : 'https://cdn.forbes.sk/uploads/2022/05/forbes_placeholder.png'
            }
            alt={articleData?.title}
            className="h-full sm:h-36"
            aspectWide
            square
            initials="..."
          />
          <div className="space-y-1.5 max-w-lg">
            <h3 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8">
              {articleData?.title}
            </h3>
            <div className="flex  gap-1 text-sm/5 text-zinc-500">
              <span>{articleData?.country}</span>
              <span>·</span>
              <span>{formatDate(articleData?.publishDate)}</span>
              <span>·</span>
              <span>{articleData?.categoryName}</span>
            </div>
          </div>
        </div>

        <div className="flex self-end gap-4 w-full sm:w-fit sm:flex-row flex-col mt-5">
          <Button
            className="w-full sm:w-fit cursor-pointer"
            href={articleData?.link}
            target="_blank"
            outline
          >
            Open Article
          </Button>
          <Button
            className="w-full sm:w-fit cursor-pointer"
            onClick={() => setIsOpen(true)}
          >
            View Details
          </Button>
          <Button
            className="w-full sm:w-fit cursor-pointer"
            onClick={() => deleteArticle()}
            color="red"
          >
            Delete
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-16 mt-10 lg:grid-cols-3">
        <Tile
          title={'Total clicks'}
          value={articleData?.totalClicks ?? 0}
          change={getClicksPercentageChange()}
          timePeriod={timePeriod}
        />

        <Tile
          title={'Total users'}
          value={articleData?.totalUsers ?? 0}
          change={getUsersPercentageChange()}
          timePeriod={timePeriod}
        />

        <Tile
          title={'Total pageviews'}
          value={pageviews?.total ?? 0}
          change={getPageviewsPercentageChange()}
          timePeriod={timePeriod}
        />
      </div>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} size="5xl">
        <DialogTitle>Article Details</DialogTitle>

        <DialogBody>
          <DescriptionList className="border-t border-zinc-950/10 mt-4">
            {[
              { term: 'Title', value: articleData?.title },
              { term: 'Link', value: articleData?.link },
              {
                term: 'Publish Date',
                value: formatDate(articleData?.publishDate),
              },
              {
                term: 'Perex',
                value: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articleData?.perex ?? '',
                    }}
                  />
                ),
              },
              { term: 'Category', value: articleData?.categoryName },
              { term: 'Category Link', value: articleData?.categoryLink },
              {
                term: 'Tags',
                value: articleData?.articleTags
                  .map((tag) => tag.name)
                  .join(', '),
              },
              { term: 'Author Name', value: articleData?.authorName },
              { term: 'Author Link', value: articleData?.authorLink },
              { term: 'Country', value: articleData?.country },
              { term: 'Featured Media', value: articleData?.featuredmedia },
              { term: 'Post ID', value: articleData?.postId },
              { term: 'Redirect URL', value: articleData?.redirectUrl },
              {
                term: 'Created At',
                value: formatDate(articleData?.createdAt),
              },
              {
                term: 'Updated At',
                value: formatDate(articleData?.updatedAt),
              },
            ].map(({ term, value }) => (
              <React.Fragment key={term}>
                <DescriptionTerm>{term}</DescriptionTerm>
                <DescriptionDetails className="break-all">
                  {renderValue(value)}
                </DescriptionDetails>
              </React.Fragment>
            ))}
          </DescriptionList>
        </DialogBody>

        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title="Confirm Deletion"
      >
        <DialogBody>
          <p>The article will be deleted permanently. Are you sure?</p>
        </DialogBody>
        <DialogActions>
          <Button
            className="w-full sm:w-fit cursor-pointer"
            onClick={() => handleDeleteConfirmation(true)}
            color="red"
          >
            Confirm
          </Button>
          <Button
            className="w-full sm:w-fit cursor-pointer"
            onClick={() => handleDeleteConfirmation(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ArticleDetail;
