import { useEffect } from 'react';
import { toast } from 'react-toastify';

const ErrorComponent = ({
  message,
  error,
  size = 'large',
}: {
  message?: string;
  error?: Error;
  size?: 'small' | 'medium' | 'large';
}) => {
  const sizeClass =
    size === 'small' ? 'h-20' : size === 'medium' ? 'h-40' : 'h-screen';

  useEffect(() => {
    if (error) console.error(error);
    if (message) toast.error(message);
  }, []);

  return (
    <div
      className={`flex flex-col items-center justify-center ${sizeClass} text-center`}
    >
      <h3 className="text-2xl mb-4 font-bold">Whoops, something went wrong.</h3>
      <p>
        You can try to refresh the page or check the console for more details.
      </p>
    </div>
  );
};

export default ErrorComponent;
