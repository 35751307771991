import { useEffect, useState } from 'react';

import { statisticsService } from '../services/StatisticsService';
import { Statistics, StatisticsResponse } from '../types/statisticsTypes';

const useStatistics = () => {
  const initialStatistics: Statistics = {
    totalCount: 0,
    count1Day: 0,
    count7Days: 0,
    count30Days: 0,
    count90Days: 0,
    percentageChange1Day: 0,
    percentageChange7Days: 0,
    percentageChange30Days: 0,
    percentageChange90Days: 0,
  };

  const [articleStatistics, setArticleStatistics] =
    useState<Statistics>(initialStatistics);
  const [clickStatistics, setClickStatistics] =
    useState<Statistics>(initialStatistics);
  const [userStatistics, setUserStatistics] =
    useState<Statistics>(initialStatistics);

  const { data, error, isLoading, isError } = statisticsService.getStatistics();

  const calculateStats = (count: number, totalCount: number) => {
    const percentageChange = totalCount === 0 ? 0 : (count / totalCount) * 100;
    return {
      count,
      percentageChange: isNaN(percentageChange)
        ? 0
        : parseFloat(percentageChange.toFixed(2)),
    };
  };

  const calculateStatistics = (
    data: StatisticsResponse,
    setStatistics: React.Dispatch<React.SetStateAction<Statistics>>,
  ) => {
    try {
      const { totalCount } = data;

      const dayStats = calculateStats(data.countLastDay, totalCount);
      const day7Stats = calculateStats(data.countLast7Days, totalCount);
      const day30Stats = calculateStats(data.countLast30Days, totalCount);
      const day90Stats = calculateStats(data.countLast90Days, totalCount);

      setStatistics({
        totalCount,
        count1Day: dayStats.count,
        count7Days: day7Stats.count,
        count30Days: day30Stats.count,
        count90Days: day90Stats.count,
        percentageChange1Day: dayStats.percentageChange,
        percentageChange7Days: day7Stats.percentageChange,
        percentageChange30Days: day30Stats.percentageChange,
        percentageChange90Days: day90Stats.percentageChange,
      });
    } catch (error) {
      console.error('Error calculating statistics:', error);
    }
  };

  const calculatePercentageChange = (
    oldNumber: number,
    newNumber: number,
  ): number => {
    if (oldNumber === 0) {
      return newNumber === 0 ? 0 : 100;
    }
    const change = ((newNumber - oldNumber) / oldNumber) * 100;
    return parseFloat(change.toFixed(2));
  };

  useEffect(() => {
    if (data) {
      const {
        articleStatistics: articleStats,
        clickStatistics: clickStats,
        userStatistics: userStats,
      } = data;

      calculateStatistics(articleStats, setArticleStatistics);
      calculateStatistics(clickStats, setClickStatistics);
      calculateStatistics(userStats, setUserStatistics);
    }
  }, [data]);

  return {
    articleStatistics,
    clickStatistics,
    userStatistics,
    calculatePercentageChange,
    isLoading,
    error,
    isError,
  };
};

export default useStatistics;
