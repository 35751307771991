import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from '../components/catalyst-ui/description-list';
import { Heading } from '../components/catalyst-ui/heading';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import PaginationComponent from '../components/PaginationComponent';
import TableComponent from '../components/TableComponent';
import { userService } from '../services/UserService';

const UserDetail: React.FC = () => {
  const { id } = useParams();
  const [articlePage, setArticlePage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [tagPage, setTagPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const {
    data: userData,
    isLoading,
    isError,
    error,
  } = userService.getUserById(id ?? '');

  const getPaginatedData = (
    items: { data: (string | number)[]; url?: string }[],
    currentPage: number,
    itemsPerPage: number,
  ) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  if (isLoading) {
    return <LoadingSpinner size="full" />;
  }

  if (isError) {
    return <ErrorComponent message={'Error fetching user'} error={error} />;
  }

  return (
    <div>
      <Link to="/users">
        <div className="flex items-center gap-2 mb-10">
          <ChevronLeftIcon className="size-4 text-zinc-500" />
          <span className="text-md text-zinc-500">Users</span>
        </div>
      </Link>
      {id && userData && (
        <div>
          <Heading>User #{id}</Heading>

          <div className="mt-12">
            <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
              User Details
            </h2>
            <DescriptionList className="border-t border-zinc-950/10 mt-4">
              {[
                { term: 'User Token', value: userData.userToken },
                { term: 'User ID', value: userData.userId },
                { term: 'Matching ID', value: userData.matchingId },
              ].map(({ term, value }) => (
                <React.Fragment key={term}>
                  <DescriptionTerm>{term}</DescriptionTerm>
                  <DescriptionDetails className="break-all">
                    {value ?? '-'}
                  </DescriptionDetails>
                </React.Fragment>
              ))}
            </DescriptionList>
          </div>

          {/* Articles */}
          <div className="mt-12">
            <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
              User Articles
            </h2>
            {(userData?.articles || [])?.length > 0 ? (
              <>
                <TableComponent
                  headers={['Article ID', 'Article Title']}
                  rows={getPaginatedData(
                    (userData?.articles || []).map((article) => ({
                      data: [article.postId, article.title],
                      url: `/articles/${article.postId}`,
                    })),
                    articlePage,
                    ITEMS_PER_PAGE,
                  )}
                />
                <PaginationComponent
                  currentPage={articlePage}
                  totalResults={Math.ceil(userData?.articles?.length ?? 0)}
                  itemPerPage={ITEMS_PER_PAGE}
                  onPageChange={setArticlePage}
                  onBackButtonClick={() =>
                    setArticlePage((prevPage) => prevPage - 1)
                  }
                  onNextButtonClick={() =>
                    setArticlePage((prevPage) => prevPage + 1)
                  }
                />
              </>
            ) : (
              <p className="text-sm text-zinc-500">No articles found</p>
            )}
          </div>

          {/* Categories */}
          <div className="mt-12">
            <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
              User Categories
            </h2>
            {(userData?.categories || [])?.length > 0 ? (
              <>
                <TableComponent
                  headers={['Category ID', 'Category Name', 'Count']}
                  rows={getPaginatedData(
                    (userData?.categories || [])
                      .slice()
                      .sort((a, b) => b.count - a.count)
                      .map((category) => ({
                        data: [
                          category.categoryId,
                          category.categoryName ?? '-',
                          category.count,
                        ],
                      })),
                    categoryPage,
                    ITEMS_PER_PAGE,
                  )}
                />
                <PaginationComponent
                  currentPage={categoryPage}
                  totalResults={Math.ceil(userData?.categories?.length ?? 0)}
                  itemPerPage={ITEMS_PER_PAGE}
                  onPageChange={setCategoryPage}
                  onBackButtonClick={() =>
                    setCategoryPage((prevPage) => prevPage - 1)
                  }
                  onNextButtonClick={() =>
                    setCategoryPage((prevPage) => prevPage + 1)
                  }
                />
              </>
            ) : (
              <p className="text-sm text-zinc-500">No categories found</p>
            )}
          </div>

          {/* Tags */}
          <div className="mt-12">
            <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
              User Tags
            </h2>
            {(userData?.tags || [])?.length > 0 ? (
              <>
                <TableComponent
                  headers={['Tag ID', 'Tag Name', 'Count']}
                  rows={getPaginatedData(
                    (userData?.tags || [])
                      .slice()
                      .sort((a, b) => b.count - a.count)
                      .map((tag) => ({
                        data: [tag.tagId, tag.tagName ?? '-', tag.count],
                      })),
                    tagPage,
                    ITEMS_PER_PAGE,
                  )}
                />
                <PaginationComponent
                  currentPage={tagPage}
                  totalResults={Math.ceil(userData?.tags?.length ?? 0)}
                  itemPerPage={ITEMS_PER_PAGE}
                  onPageChange={setTagPage}
                  onBackButtonClick={() =>
                    setTagPage((prevPage) => prevPage - 1)
                  }
                  onNextButtonClick={() =>
                    setTagPage((prevPage) => prevPage + 1)
                  }
                />
              </>
            ) : (
              <p className="text-sm text-zinc-500">No tags found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetail;
