import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ApiClient } from '../apiClient/apiClient';
import { AnalyticsResponse } from '../types/analytics';

const apiClient = ApiClient.getInstance();

export const analyticService = {
  getAnalyticsData(
    country: 'CZ' | 'SK' | 'HU' | null,
    range: '1' | '7' | '90' | null,
    page: number,
  ): UseQueryResult<AnalyticsResponse> {
    return useQuery<AnalyticsResponse>({
      queryKey: ['analytics', country, range, page],
      queryFn: async () => {
        const response = await apiClient.get('/article/by-views', {
          page,
          limit: 10,
          language: country,
          viewPeriod: range,
        });
        return response as unknown as AnalyticsResponse;
      },
      enabled: Boolean(country && range),
    });
  },
};
