import React from 'react';

import { Avatar } from '../components/catalyst-ui/avatar';
import { Link } from '../components/catalyst-ui/link';
import { RecommendationType } from '../types/recommendation';
import { formatDate } from '../utils/formatDate';

interface RecommendationCardProps {
  recommendation: RecommendationType;
  position: number; // Position of the card in the results
  horizontalLayout?: boolean; // Optional prop to toggle horizontal layout
}

const RecommendationCard: React.FC<RecommendationCardProps> = ({
  recommendation,
  position,
  horizontalLayout = false,
}) => {
  return (
    <div
      className={`${
        horizontalLayout
          ? 'xl:flex xl:flex-row xl:items-center xl:gap-4 xl:h-[8rem] xl:text-xs'
          : 'flex flex-col'
      }`}
    >
      <Avatar
        src={
          recommendation.featuredmedia &&
          recommendation.featuredmedia !== 'no-image'
            ? recommendation.featuredmedia
            : 'https://cdn.forbes.sk/uploads/2022/05/forbes_placeholder.png'
        }
        alt={recommendation.title}
        square
        aspectWide
        className={horizontalLayout ? 'xl:h-32 mb-2' : 'mb-2'}
      />
      <div className="flex-1">
        <h3
          className={`text-md font-semibold mb-1 ${
            horizontalLayout ? 'line-clamp-2 xl:text-sm' : ''
          }`}
        >
          {recommendation.title}
        </h3>
        <p className="text-zinc-600 mb-1 ">Position: {position}</p>
        <p className="text-zinc-600 mb-1">
          Category: {recommendation.categoryName}
        </p>
        <div className="flex text-zinc-600 mb-1">
          <span className="mr-4">{recommendation.authorName}</span>
          <span>{formatDate(recommendation.publishDateTimestamp)}</span>
        </div>
        <Link href={recommendation.link}>Read More</Link>
      </div>
    </div>
  );
};

export default RecommendationCard;
