import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ApiClient } from '../apiClient/apiClient';
import {
  PageviewsByArticleIdResponse,
  StatisticsApiResponse,
} from '../types/statisticsTypes';

const apiClient = ApiClient.getInstance();

export const statisticsService = {
  getStatistics(): UseQueryResult<StatisticsApiResponse> {
    return useQuery<StatisticsApiResponse>({
      queryKey: ['statistics'],
      queryFn: async () => {
        const response = await apiClient.get('/statistics/statistics');
        return response as unknown as StatisticsApiResponse;
      },
    });
  },

  getPageviewsByArticleId(
    id: string,
  ): UseQueryResult<PageviewsByArticleIdResponse> {
    return useQuery<PageviewsByArticleIdResponse>({
      queryKey: ['pageviews', id],
      queryFn: async () => {
        const response = await apiClient.get(
          `/statistics/article-pageviews-statistics`,
          {
            postId: id,
          },
        );
        return response as unknown as PageviewsByArticleIdResponse;
      },
    });
  },
};
