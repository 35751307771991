import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from '../components/catalyst-ui/button';
import { Heading } from '../components/catalyst-ui/heading';
import { Input } from '../components/catalyst-ui/input';
import RecommendationCard from '../components/RecommendationCard';
import { recommendationService } from '../services/RecommendationService'; // Import the service
import { RecommendationType } from '../types/recommendation';

const Recommendation = () => {
  const [url, setUrl] = useState('');
  const [results, setResults] = useState<{
    recommendations: RecommendationType[];
  } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const handleFetchRecommendations = async () => {
    if (!url.trim()) {
      toast.error('Please enter a valid URL');
      return;
    }

    setLoading(true);

    setResults(null);

    try {
      const data = await recommendationService.getRecommendationsFromUrl(url); // Call the service
      setResults(data);
    } catch (err) {
      toast.error('Error fetching recommendations');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <Heading className="pb-4">
        AI Recommendation Tester{' '}
        {results && results.recommendations.length > 0 && (
          <> Results ({results.recommendations.length})</>
        )}
      </Heading>
      <Input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        className="mb-3 "
        placeholder="Paste URL here"
      />
      <Button
        id="submit"
        onClick={handleFetchRecommendations}
        className="w-full p-2 bg-black text-white font-bold rounded"
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Get Recommendations'}
      </Button>

      {results && (
        <div id="results" className="mt-8">
          {/* Special layout for the first 5 articles */}
          <div className="grid grid-cols-1 xl:grid-cols-4 xl:grid-rows-4 gap-4 mb-8 ">
            {/* First article (large, spans 2 columns and all rows) */}
            {results.recommendations[0] && (
              <div className="xl:col-span-2 xl:row-span-4">
                <RecommendationCard
                  recommendation={results.recommendations[0]}
                  position={1}
                />
              </div>
            )}

            {/* Next 4 articles (stacked vertically to the right) */}
            {results.recommendations.slice(1, 5).map((rec, index) => (
              <div
                key={index + 1}
                className={`xl:col-start-3 xl:col-end-5 xl:row-start-${
                  index + 1
                } xl:row-end-${index + 2} gb-2`}
              >
                <RecommendationCard
                  recommendation={rec}
                  position={index + 2}
                  horizontalLayout
                />
              </div>
            ))}
          </div>

          {/* Standard grid for the remaining articles */}
          {results.recommendations.length > 5 && (
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
              {results.recommendations.slice(5).map((rec, index) => (
                <RecommendationCard
                  key={index + 5}
                  recommendation={rec}
                  position={index + 6}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Recommendation;
