import { useEffect, useState } from 'react';

import { Select } from '../components/catalyst-ui/select';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import Tile from '../components/Tile';
import { authService } from '../context/AuthContext';
import useStatistics from '../hooks/useStatistics';
import { TimePeriod } from '../types/statisticsTypes';
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter';
import Articles from './Articles';

const Overview = () => {
  const DAY = '1Day';
  const SEVEN_DAYS = '7Days';
  const THIRTY_DAYS = '30Days';
  const NINETY_DAYS = '90Days';
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(DAY);
  const [userName, setUserName] = useState<string>('Guest');

  const {
    articleStatistics,
    clickStatistics,
    userStatistics,
    isLoading,
    isError,
  } = useStatistics();

  useEffect(() => {
    const user = authService.getUser();
    if (user) {
      setUserName(capitalizeFirstLetter(user.firstName));
    }
  }, []);

  const handleTimePeriodChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setTimePeriod(event.target.value as TimePeriod);
  };

  const getTotalArticlesValues = () => {
    return articleStatistics[`count${timePeriod}`];
  };

  const getTotalArticlesChange = () => {
    return articleStatistics[`percentageChange${timePeriod}`];
  };

  const getTotalUsersValues = () => {
    return userStatistics[`count${timePeriod}`];
  };

  const getTotalUsersChange = () => {
    return userStatistics[`percentageChange${timePeriod}`];
  };

  const getTotalClicksValues = () => {
    return clickStatistics[`count${timePeriod}`];
  };

  const getTotalClicksChange = () => {
    return clickStatistics[`percentageChange${timePeriod}`];
  };

  return (
    <div>
      <h1 className="text-2xl/8 font-semibold text-zinc-950 sm:text-xl/8 ">
        Welcome, {userName}
      </h1>
      <div className="mt-8 flex items-end justify-between">
        <h2 className="text-base/7 font-semibold text-zinc-950 sm:text-sm/6">
          Overview
        </h2>
        <div className="text-sm">
          <div>
            <Select value={timePeriod} onChange={handleTimePeriodChange}>
              <option value={DAY}>1 day</option>
              <option value={SEVEN_DAYS}>7 days</option>
              <option value={THIRTY_DAYS}>30 days</option>
              <option value={NINETY_DAYS}>90 days</option>
            </Select>
          </div>
        </div>
      </div>
      {isLoading ? (
        <LoadingSpinner size="medium" />
      ) : isError ? (
        <ErrorComponent size="medium" />
      ) : (
        <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
          <Tile
            title="Total Articles"
            value={getTotalArticlesValues()}
            change={getTotalArticlesChange()}
            timePeriod={timePeriod}
          />
          <Tile
            title="Total users"
            value={getTotalUsersValues()}
            change={getTotalUsersChange()}
            timePeriod={timePeriod}
          />
          <Tile
            title="Total clicks"
            value={getTotalClicksValues()}
            change={getTotalClicksChange()}
            timePeriod={timePeriod}
          />
        </div>
      )}

      <div className="mt-10">
        <Articles
          withPagination={false}
          withSorting={false}
          withSearch={false}
        />
      </div>
    </div>
  );
};

export default Overview;
