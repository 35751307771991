import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar } from '../components/catalyst-ui/avatar';
import { Badge } from '../components/catalyst-ui/badge';
import { Heading } from '../components/catalyst-ui/heading';
import { Input } from '../components/catalyst-ui/input';
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from '../components/catalyst-ui/listbox';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '../components/catalyst-ui/table';
import ErrorComponent from '../components/ErrorComponent';
import { LoadingSpinner } from '../components/LoadingSpinner';
import PaginationComponent from '../components/PaginationComponent';
import Tooltip from '../components/Tooltip';
import { useDebounce } from '../hooks/useDebounce';
import { articleService } from '../services/ArticleService';
import { ArticleResponse } from '../types/articleTypes';
import { formatDate } from '../utils/formatDate';

export type ArticlesProps = {
  withPagination?: boolean;
  withSorting?: boolean;
  withSearch?: boolean;
};

const Articles = ({
  withPagination = true,
  withSorting = true,
  withSearch = true,
}: ArticlesProps) => {
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get('page');
  const countryParam = searchParams.get('country');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(
    pageParam ? parseInt(pageParam, 10) : 1,
  );
  const [sortByCountry, setSortByCountry] = useState<
    'HU' | 'CZ' | 'SK' | 'ALL' | ''
  >(countryParam ? (countryParam as 'HU' | 'CZ' | 'SK' | 'ALL' | '') : '');
  const [query, setQuery] = useState('');
  const searchQuery = useDebounce(query, 300);

  const {
    data: articles,
    isLoading,
    isError,
    error,
  } = articleService.getArticles(currentPage, 10, searchQuery, sortByCountry);

  const totalResults = articles?.totalResults ?? 0;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (sortByCountry) {
      params.set('country', sortByCountry);
    } else {
      params.delete('country');
    }
    navigate({ search: params.toString() }, { replace: true });
  }, [sortByCountry]);

  const navigateToArticle = (article: ArticleResponse) => {
    navigate(`/articles/${article.postId}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length >= 3) {
      setQuery(value);
      setCurrentPage(1);
    } else {
      setQuery('');
    }
  };

  const handleSortByCountryChange = (country: 'HU' | 'CZ' | 'SK' | 'ALL') => {
    setSortByCountry(country);
    setCurrentPage(1);
  };

  if (isError) {
    return <ErrorComponent message="Error fetching articles" error={error} />;
  }

  return (
    <div>
      <div className="flex flex-col w-full flex-wrap justify-between gap-4 border-b border-zinc-100/100 pb-6">
        <Heading>Articles</Heading>
        <div className="flex items-center max-w-lg gap-4">
          {withSearch && (
            <Tooltip
              content="Search for articles by title, content, author or category"
              position="bottom"
              minWidth="250px"
            >
              <Input placeholder="Search..." onChange={handleSearchChange} />
            </Tooltip>
          )}
          {withSorting && (
            <Listbox
              name="country"
              placeholder="Sort by country"
              onChange={handleSortByCountryChange}
              defaultValue={sortByCountry}
            >
              <ListboxOption value="" disabled>
                <ListboxLabel>Sort by country</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="CZ">
                <ListboxLabel>Czech Republic</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="SK">
                <ListboxLabel>Slovakia</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="HU">
                <ListboxLabel>Hungary</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="ALL">
                <ListboxLabel>All</ListboxLabel>
              </ListboxOption>
            </Listbox>
          )}
        </div>
      </div>

      {/* Table */}
      {isLoading ? (
        <LoadingSpinner size="large" />
      ) : articles?.data && articles?.data?.length > 0 ? (
        <Table>
          <TableBody>
            {articles?.data?.map((article) => (
              <TableRow
                key={article._id}
                onClick={() => navigateToArticle(article)}
                className="cursor-pointer hover:underline"
              >
                <TableCell id="article-image" className="w-36">
                  <Avatar
                    src={
                      article.featuredmedia &&
                      article.featuredmedia !== 'no-image'
                        ? article.featuredmedia
                        : 'https://cdn.forbes.sk/uploads/2022/05/forbes_placeholder.png'
                    }
                    alt={article.title}
                    aspectWide
                    square
                    initials="..."
                  />
                </TableCell>

                <TableCell id="article-details" className="w-2/3">
                  <div className="flex flex-col space-y-1.5">
                    <h3 className="text-base/6 font-semibold line-clamp-2 sm:line-clamp-1">
                      {article.title}
                    </h3>
                    <div className="flex items-center gap-1 text-sm/5 text-zinc-500 flex-wrap">
                      <span>{formatDate(article.publishDate)} </span>
                      <span>·</span>
                      <span>{article.categoryName}</span>
                    </div>

                    <div className="flex items-center gap-1 text-sm/5 text-zinc-500">
                      <span>{article.authorName}</span>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="w-1/6">
                  <Badge color="zinc">{article.country}</Badge>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div className="mt-4 text-center text-gray-500">No articles found</div>
      )}
      {withPagination && (
        <PaginationComponent
          currentPage={currentPage}
          itemPerPage={10}
          totalResults={totalResults}
          onBackButtonClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          onNextButtonClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};

export default Articles;
